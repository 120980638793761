import { H } from "highlight.run";
import store, { key, DEFAULT_STORE } from "./store";
import { isApp } from "./utils";

let current_store = localStorage.getItem(key);
if (current_store) {
  current_store = JSON.parse(current_store);
  mergeDeep(current_store, DEFAULT_STORE);
  // @ts-ignore
  store.set(current_store);
}

function mergeDeep(target: any, source: any) {
  for (let key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        if (!target[key]) {
          target[key] = {};
        }
        mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
}

H.init("memn9xg2", {
  environment: "prod",
  manualStart: true,
  version: isApp() ? "app" : "web",
  tracingOrigins: ["localhost", "127.0.0.1", "divine-sunset-8066.fly.dev"],
  enableStrictPrivacy: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
  integrations: {
    mixpanel: {
      projectToken: "403865b2a055dc32602e23c7feaf54b7",
    },
  },
});
