import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [8],
		"/auth": [9,[2]],
		"/auth/reset": [10,[2]],
		"/home": [11,[3]],
		"/insights": [12,[4]],
		"/lockscreen": [13],
		"/notebook": [14,[5]],
		"/notebook/chat": [15,[5]],
		"/notebook/journal": [16,[5]],
		"/notebook/journal/[slug]": [17,[5]],
		"/notebook/talk": [~18,[5]],
		"/popup": [19,[6]],
		"/settings": [20],
		"/therapists": [21],
		"/welcome": [22,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';